// SkillButtonsAndQuestionGenerator.tsx
import React, { useState, useContext, useCallback, FormEvent } from 'react';
import { UserContext, SkillData, Skills, UserData } from './UserContext';
//imports SkillData and Skills type, 
//This is the same format as UserData but used for updating session data if no user is logged in
import { PromptContext } from './PromptContext';
import { motion, AnimatePresence } from "framer-motion";
import { Box, Button, Typography, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import LinearProgress from '@mui/material/LinearProgress';

type SkillButtonsAndQuestionGeneratorProps = {
  selectedSubject: string; // 'Math' or 'Reading and Writing'
};

// Add new types and states
interface SeriesState {
  isActive: boolean;
  currentQuestionIndex: number;
  difficulty: 'Easy' | 'Medium' | 'Hard';
  totalQuestionsInSeries: number;
  answers: Array<{
    questionText: string;
    selectedAnswer: string;
    isCorrect: boolean;
  }>;
}

const skillsByType: Record<string, string[]> = {
  "Reading and Writing": ['Function of Sentence', 'Inferences', 'Main Idea', 'Pronouns and Modifiers', 'Punctuation', 'Referencing Data', 'Supporting Claims', "Synthesizing Notes", 'Tenses', 'Transition Words', 'Two Passages', 'Word Choice'],
  "Math": ['Absolute Value', 'Algebra', 'Circles', 'Exponential Equations', 'Exponential Word Problems', 'Geometry', 'Interpreting Graphs', 'Linear Equations', 'Linear Word Problems', 'Inequality Word Problems', 'Percent', 'Polynomial Expressions', 'Probability', 'Quadratic Equations', 'Statistics', 'Systems of Equations', 'Trigonometry', 'Unit Conversions'],
};

const skillFrequency: Record<string, number> = {
  'Function of Sentence': .02,
  'Inferences': .05,
  'Main Idea': .054,
  'Pronouns and Modifiers': .023,
  'Punctuation': .084,
  'Referencing Data': .027,
  'Supporting Claims': .047,
  'Synthesizing Notes': .068,
  'Tenses': .036,
  'Transition Words': .061,
  'Two Passages': .009,
  'Word Choice': .099,
  'Absolute Value': .002,
  'Algebra': .027,
  'Circles': .011,
  'Exponential Equations': .009,
  'Exponential Word Problems': .014,
  'Geometry': .059,
  'Interpreting Graphs': .029,
  'Linear Equations': .045,
  'Linear Word Problems': .059,
  'Inequality Word Problems': .011,
  'Percent': .018,
  'Polynomial Expressions': .016,
  'Probability': .009,
  'Quadratic Equations': .047,
  'Statistics': .014,
  'Systems of Equations': .029,
  'Trigonometry': .014,
  'Unit Conversions': .009
}

// Function to convert display names to skill keys
const skillNameToKey = (skillName: string): string => {
  return skillName.toLowerCase().replace(/ /g, '_');
};

const createInitialSkillData = (): SkillData => ({
  currentTestQuestionsAttempted: 0,
  currentTestQuestionsCorrect: 0,
  currentTestEasyQuestionsAttempted: 0,
  currentTestEasyQuestionsCorrect: 0,
  easyQuestionsIndex: 0,
  currentTestMediumQuestionsAttempted: 0,
  currentTestMediumQuestionsCorrect: 0,
  mediumQuestionsIndex: 0,
  currentTestHardQuestionsAttempted: 0,
  currentTestHardQuestionsCorrect: 0,
  hardQuestionsIndex: 0,
  overallQuestionsAttempted: 0,
  overallQuestionsCorrect: 0
});

const SkillButtonsAndQuestionGenerator: React.FC<SkillButtonsAndQuestionGeneratorProps> = ({ selectedSubject }) => {
  
  const { user, updateUserData } = useContext(UserContext);
  const {questionData, questionText, choices, correctAnswer, explanationText, setQuestionText, setChoices, setCorrectAnswer, setExplanationText, 
    selectedSkill, setSelectedSkill, selectedDifficulty, setSelectedDifficulty, clearChat, renderLatexOrText, 
    interactionStage, setInteractionStage} = useContext(PromptContext)!;
    //interactionStage is used by ChatComponent to dynamically change quickPrompts - don't delete!
  const [selectedAnswer, setSelectedAnswer] = useState<string>('');
  const [freeResponseInput, setFreeResponseInput] = useState<string>(''); // New: State for free-response input
  const [feedback, setFeedback] = useState<string>('');
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState<boolean>(false);
  const [incorrectAnswers, setIncorrectAnswers] = useState<string[]>([]); //incorrect answers that have been subitted, so the user can try again
  const [hasSubmittedFirstAttempt, setHasSubmittedFirstAttempt] = useState<boolean>(false);
  const [seriesState, setSeriesState] = useState<SeriesState>({
    isActive: false,
    currentQuestionIndex: 0,
    difficulty: 'Easy',
    totalQuestionsInSeries: 5,
    answers: [],
  });
  const [sessionIndices, setSessionIndices] = useState<Skills>({}); //mirrors userData's skills object

  const updateProgress = (skillKey: string, updates: Partial<SkillData>) => {
    const currentData = user?.skills[skillKey] || sessionIndices[skillKey] || createInitialSkillData();
    const updatedData = { ...currentData, ...updates };
  
    if (user) {//only updates user if user is logged in
      const userUpdates: Partial<UserData> = {
        skills: { ...user.skills, [skillKey]: updatedData }
      };

      // Track attempted questions
      if (updates.overallQuestionsAttempted && updates.overallQuestionsAttempted > currentData.overallQuestionsAttempted) {
        const attemptedIncrement = updates.overallQuestionsAttempted - currentData.overallQuestionsAttempted;
        userUpdates.grandTotalQuestionsAttempted = (user.grandTotalQuestionsAttempted || 0) + attemptedIncrement;
      }

      // Track correct questions
      if (updates.overallQuestionsCorrect && updates.overallQuestionsCorrect > currentData.overallQuestionsCorrect) {
        const correctIncrement = updates.overallQuestionsCorrect - currentData.overallQuestionsCorrect;
        userUpdates.grandTotalQuestionsCorrect = (user.grandTotalQuestionsCorrect || 0) + correctIncrement;
      }
      
      updateUserData(userUpdates);
      //Previous way of updating userData, without tracking attempted and correct questions at the overall, not skill level
      //const updatedSkills = { ...user.skills, [skillKey]: updatedData };
      //updateUserData({ skills: updatedSkills });
    }
  
    setSessionIndices(prev => ({//always updates sessionIndices
      ...prev,
      [skillKey]: updatedData
    }));
  };
  
  const checkAndUpdatePracticeDate = () => {
    if (!user) return;

    const today = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
    if (user.lastPracticedDate !== today) {
      // If the last practiced date is not today, increment totalDaysPracticed
      updateUserData({
        totalDaysPracticed: user.totalDaysPracticed + 1,
        lastPracticedDate: today,
      });
    }
  };
  // New function to start question series
  const startQuestionSeries = async (difficulty: 'Easy' | 'Medium' | 'Hard') => {
    //console.log(`Starting question series for ${selectedSkill} at ${difficulty} difficulty.`);
    //Update totalDaysPracticed and lastPracticedDate if today's date is different from the last time practiced
    checkAndUpdatePracticeDate(); //used for PersonalizedBar.tsx to display Days in a Streak and Total Days Practiced
    setSeriesState({
      isActive: true,
      currentQuestionIndex: 0,
      difficulty,
      totalQuestionsInSeries: 5,
      answers: [],
    });
    await generateQuestion(selectedSkill, difficulty);
  };

  const generateQuestion = useCallback(async (skill: string, difficulty: 'Easy' | 'Medium' | 'Hard', isPrevious: boolean = false) => {
    if (skill === "") {
      console.error('No skill selected. Please try again.');
      return;
    }
    
    //This will make skill accessible throughout components.
    setSelectedSkill(skill);
    setSelectedDifficulty(difficulty);
    setFeedback(''); //clears the feedback from the previous question
    clearChat(); //clears Chat history (in PromptContext) when new question is generator to not clog up ChatComponent
    setSelectedAnswer(''); //clears previous answer choice (A, etc.) so it's not highlighted if the same skill question is generated
    setCorrectAnswer(''); //clear previous correct answer (D, etc.) so it's not highlighted if the same skill question is generated
    setIsAnswerSubmitted(false);
    setIncorrectAnswers([]) //reset incorrect answers from the previous question
    setHasSubmittedFirstAttempt(false);

    try {
      if (!questionData) throw new Error('Questions are not loaded yet.');

      // Retrieve current question index based on difficulty
      const skillKey = skillNameToKey(skill);
      const currentData = user?.skills[skillKey] || sessionIndices[skillKey] || createInitialSkillData();
      const difficultyKey = `${difficulty.toLowerCase()}QuestionsIndex` as keyof SkillData; //"keyof SkillData" will update if SkillData changes vs. explicit text above
      //Ex of difficultyKey: 'easyQuestionsIndex' | 'mediumQuestionsIndex' | 'hardQuestionsIndex'

      //Filter questions based on skill and difficulty
      const questions = questionData.filter(q => q.skill === skill && q.difficulty === difficulty);
      console.log(`${questions.length} ${difficulty} questions for ${skill}.`);

      if (questions.length === 0) {
        throw new Error(`No questions found for ${skill} at ${difficulty} difficulty.`);
      }

      const currentQuestionIndex = isPrevious? 
        (currentData[difficultyKey] - 2 + questions.length) % questions.length // Going backwards 2 and forwards 1; adding questions.length then dividing and taking remainder means it always works
        : currentData[difficultyKey]

      //Previous functional:
      //const currentQuestionIndex = currentData[difficultyKey];


      /*Not quite working:
      const newQuestionIndex = isPrevious ? 
        (currentQuestionIndex - 1 + questions.length) % questions.length // Going backwards; adding questions.length then dividing and taking remainder means it always works
        : (currentQuestionIndex + 1) % questions.length;    
        */
      //Previous working version: 
      const newQuestionIndex = (currentQuestionIndex + 1) % questions.length;
      console.log(`newQuestionIndex is ${newQuestionIndex}`);
      const selectedQuestion = questions[currentQuestionIndex]

      // Set the state variables with the selected question data
      setQuestionText(selectedQuestion.question);
      setChoices(selectedQuestion.choices || '');
      setCorrectAnswer(selectedQuestion.answer);
      setExplanationText(selectedQuestion.explanation || '');
      // Set interaction stage after successfully generating a question for use when rendering quickPrompts - pre-question, during, or after submitting answer?
      setInteractionStage('questionGenerated'); //used for dynamically changing quickPrompt questions in ChatComponent

      //UpdateProgress calls updateUserData if user logged in + updates sessionIndices
      updateProgress(skillKey, {
        [difficultyKey]: newQuestionIndex,
        [`currentTest${difficulty}QuestionsAttempted`]: currentData[`currentTest${difficulty}QuestionsAttempted` as keyof SkillData] + 1,
        overallQuestionsAttempted: currentData.overallQuestionsAttempted + 1
      });
    } catch (error) {
      console.error('Error generating question:', error);
    } 
  }, [selectedSubject, selectedSkill, setSelectedSkill, clearChat, updateProgress, user, sessionIndices, questionData,]); //Should I remove the comma after user?


  // Toggle skill selection based on which skill box the user clicks
  //Should probably implmment "updateUIState" to box up the setQuestionText, setChoice, etc. to blank
  const handleSkillClick = (skill: string) => {
    setSelectedSkill(skill === selectedSkill ? "" : skill); 
    // Clear previous question and feedback when changing skill. Added at 11:00 am on 9/19
    setQuestionText('');
    setChoices([]);
    setCorrectAnswer('');
    setExplanationText('');
    setSelectedAnswer('');
    setFeedback('');
    setInteractionStage('notStarted');
  };  

  // Simplified helper function to get data from either user or session
  //Do I need this?
  const getSkillData = (skillKey: string) => {
    // Return user data if it exists, otherwise return session data
    return user?.skills[skillKey] || sessionIndices[skillKey] || {
      currentTestEasyQuestionsAttempted: 0,
      currentTestEasyQuestionsCorrect: 0,
      currentTestMediumQuestionsAttempted: 0,
      currentTestMediumQuestionsCorrect: 0,
      currentTestHardQuestionsAttempted: 0,
      currentTestHardQuestionsCorrect: 0
    };
  };

  // Function to calculate overall skill percentage correct from user data
  const calculateSpecificDifficultyPercentage = (skill: string, difficulty: 'Easy' | 'Medium' | 'Hard') => {
    const skillData = getSkillData(skillNameToKey(skill));
    console.log(`skillData is ${skillData}. That means ${skillData[`currentTest${difficulty}QuestionsAttempted`]} attempted and ${skillData[`currentTest${difficulty}QuestionsCorrect`]} correct.`);
    const attempted = skillData[`currentTest${difficulty}QuestionsAttempted`] || 0;
    const correct = skillData[`currentTest${difficulty}QuestionsCorrect`] || 0;
    console.log(`New ${skill} ${difficulty} percentage is ${Math.round((correct / attempted) * 100)}`);
    
    return attempted === 0 ? 0 : Math.round((correct / attempted) * 100);
  };

  // Simplified attempts function
  const getAttemptsForDifficulty = (skill: string, difficulty: 'Easy' | 'Medium' | 'Hard') => {
    const skillData = getSkillData(skillNameToKey(skill));
    return skillData[`currentTest${difficulty}QuestionsAttempted`] || 0;
  };

  //This is where setFeedback can be helpful when making AN INCORRECT GUESS
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const answer = choices.length === 0 ? freeResponseInput.trim() : selectedAnswer;
    console.log(`freeResponseInput is: ${freeResponseInput}`);
    
    if (answer === '') {
      if (freeResponseInput.trim() === '') { //if it's a free response question
        setFeedback('Please enter an answer in the input box.');
        console.log(`There was no input.`);
        return;
      } else { //it's a multiple choice question
        setFeedback('Please select an answer.');
        return;
      }
    }
    const isCorrect = answer === correctAnswer;
    console.log(`answer is ${answer} and correctAnswer is ${correctAnswer} and isCorrect is ${isCorrect}`);
    /****Opportunity to provide more helpful feedback on Incorrect answers 
    RETURN HERE TO MAKE THIS BETTER IN THE FUTURE! */
    setFeedback(isCorrect ? 'Correct!' : 'Nice try but not quite - can you figure out what happened?');
    setIsAnswerSubmitted(true);
    setInteractionStage('answered');

    if (!isCorrect && choices.length > 0){ //if incorrect answer, add it to an array to keep it highlighted red
      setIncorrectAnswers((prev) => [...prev, selectedAnswer])
    } //****Might have an async problem where I need to do "setTimeout" if this doesn't work: */

    console.log(`right before checking if hasSubmittedFirstAttempt is true or false`);
    if (!hasSubmittedFirstAttempt && selectedSkill) {
      setHasSubmittedFirstAttempt(true); // Mark as the first attempt to prevent further updates
      const skillKey = skillNameToKey(selectedSkill);
      const currentData = user?.skills[skillKey] || sessionIndices[skillKey] || createInitialSkillData();

      updateProgress(skillKey, {
        [`currentTest${selectedDifficulty}QuestionsCorrect`]: 
          isCorrect ? currentData[`currentTest${selectedDifficulty}QuestionsCorrect` as keyof SkillData] + 1 : 
          currentData[`currentTest${selectedDifficulty}QuestionsCorrect` as keyof SkillData], //keep the same if incorrect
        overallQuestionsCorrect: isCorrect ? currentData.overallQuestionsCorrect + 1 : currentData.overallQuestionsCorrect
      });      
    }
    //New as of 1/23/2025 after hearing from student who couldnt' submit Free Response Questions:
    else if (choices.length === 0 && !hasSubmittedFirstAttempt) {
      setHasSubmittedFirstAttempt(true); //Mark as first attempt to log to user
    }
  };


  const handleNextQuestion = async () => {
    // Reset all question-related states
    setSelectedAnswer('');
    setFeedback('');
    setIsAnswerSubmitted(false);
    setIncorrectAnswers([]);
    setHasSubmittedFirstAttempt(false);
    setFreeResponseInput('');
  
    // Increment question index
    setSeriesState(prev => ({
      ...prev,
      currentQuestionIndex: prev.currentQuestionIndex + 1
    }));
  
    // Generate new question
    await generateQuestion(selectedSkill, seriesState.difficulty);
  };

  const handlePreviousQuestion = async () => {
    // Reset all question-related states
    setSelectedAnswer('');
    setFeedback('');
    setIsAnswerSubmitted(false);
    setIncorrectAnswers([]);
    setHasSubmittedFirstAttempt(false);
    setFreeResponseInput('');
  
    // Increment question index
    setSeriesState(prev => ({
      ...prev,
      currentQuestionIndex: prev.currentQuestionIndex - 1
    }));

    /*
    
    const skillKey = skillNameToKey(selectedSkill);
    const difficultyKey = `${selectedDifficulty.toLowerCase()}QuestionsIndex` as keyof SkillData; //"keyof SkillData" will update if SkillData changes vs. explicit text above
    const currentData = user?.skills[skillKey] || sessionIndices[skillKey];
    const currentQuestionIndex = currentData[difficultyKey];
    const newQuestionIndex = currentQuestionIndex - 1;
    console.log(`currentQuestionIndex was ${currentQuestionIndex}, and newQuestionIndex is ${newQuestionIndex}.`);
    
    ************************ERROR TO FIX on 1/23/2025:**************************************
     * If currentQuestionIndex === 0, then newQuestionIndex = 1.
    Need to test if accesing currentData's skillKey's information for [difficultyKey]: -1 will return the maximum length of the array of questions...I dont think this will work because it'll access an IndexOutOfBounds or something...right?
    
    accesing the "-1" 
    if (currentQuestionIndex === 0){ //then subtrac

    } else {
      const newQuestionIndex = currentQuestionIndex - 1;
    }
     % questions.length;
    //Don't think I need the %questions.length because the button for Previous Question is disabled on the 1st of 
    //const newQuestionIndex = (currentQuestionIndex + 1) % questions.length; 

    updateProgress(skillKey, {
      [difficultyKey]: newQuestionIndex,
      [`currentTest${selectedDifficulty}QuestionsAttempted`]: currentData[`currentTest${selectedDifficulty}QuestionsAttempted` as keyof SkillData] - 1,
      overallQuestionsAttempted: currentData.overallQuestionsAttempted - 1
    });*/
  
    // Generate new question
    await generateQuestion(selectedSkill, seriesState.difficulty, true);
  };
  
  // Add handleSkip function
  const handleSkip = async () => {
    // I might want to track skipped questions in the future?***********
    await handleNextQuestion();
  };

  const handleExtendSeries = async () => {
    setSeriesState(prev => ({
      ...prev,
      totalQuestionsInSeries: prev.totalQuestionsInSeries + 5,
      currentQuestionIndex: prev.currentQuestionIndex + 1
    }));
    await generateQuestion(selectedSkill, seriesState.difficulty);
  };


  return (
    <Box sx={{width: '100%', p: 2}}>
      <AnimatePresence>
        {!seriesState.isActive && (
          <motion.div
            initial={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
          >
            <Grid container spacing={2}>
              {skillsByType[selectedSubject].map((skill) => {
                const isSelected = selectedSkill === skill;
                
                return (
                  <Grid component="div" size={{xs:6, sm:4, md:3, lg:2}}>
                    <Button
                      variant={isSelected ? 'contained' : 'outlined'}
                      key={skill}
                      onClick={() => handleSkillClick(skill)}
                      sx={{
                        width: '100%',
                        height: 50,
                        bgcolor: isSelected ? 'primary.main' : 'background.paper',
                        '&:hover': {
                          bgcolor: isSelected ? 'primary.dark' : 'grey.100',
                        },
                      }}
                    >
                      {skill}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>

            {/* Difficulty buttons for series */}
            {selectedSkill && (
              
              <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                <Box sx={{display: 'flex', justifyContent: 'center', gap: 2, width: '100%'}}>
                  {['Easy', 'Medium', 'Hard'].map((difficulty) => {
                    const percentCorrect = calculateSpecificDifficultyPercentage(selectedSkill, difficulty as 'Easy' | 'Medium' | 'Hard');
                    const attempted = getAttemptsForDifficulty(selectedSkill, difficulty as 'Easy' | 'Medium' | 'Hard');
                    
                    return (
                      <Box key={difficulty} sx={{display: 'flex', flexDirection: 'column', 
                        alignItems: 'center', minWidth: 160}}>
                        <Typography variant="body2" sx={{ mb: 1 }}>{percentCorrect}% correct</Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>{attempted} attempted</Typography>
                        <Button key={difficulty} variant="contained"
                          onClick={() => startQuestionSeries(difficulty as 'Easy' | 'Medium' | 'Hard')}
                          sx={{ minWidth: 160, height: 48}}
                        >
                          Practice {difficulty} Questions
                        </Button>
                    </Box>
                    );
                  })}
                </Box>
              </Box>
            )}
          </motion.div>
        )}


        {/* Series mode UI */}
        {seriesState.isActive && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >

            <Box sx={{ width: '100%', mb: 3 }}>
              <LinearProgress variant="determinate" 
                value={((seriesState.currentQuestionIndex + 1) / seriesState.totalQuestionsInSeries) * 100}
                  sx={{height: 10, borderRadius: 5, backgroundColor: 'grey.200',
                    '& .MuiLinearProgress-bar': {
                      borderRadius: 5, backgroundColor: 'custom.green',
                    },
                  }}
              />
              <Box sx={{mt: 1, display: 'flex', justifyContent: 'space-between', px: 1, 
                fontSize: '0.875rem', color: 'text.secondary',
                }}
              >
                <span>0</span>
                <span>{Math.floor(seriesState.totalQuestionsInSeries * .2)}</span>
                <span>{Math.floor(seriesState.totalQuestionsInSeries * .4)}</span>
                <span>{Math.floor(seriesState.totalQuestionsInSeries * .6)}</span>
                <span>{Math.floor(seriesState.totalQuestionsInSeries * .8)}</span>
                <span>{seriesState.totalQuestionsInSeries}</span>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
              <Box sx={{ flex: '0 0 auto' }}>
                <Button startIcon={<ArrowBackIcon />}
                  onClick={() => setSeriesState(prev => ({ ...prev, isActive: false }))}
                >
                Exit Series
                </Button>
              </Box>
              <Box sx={{ flex: '1 1 auto' }}>              
                <Typography variant="h5" sx={{textAlign: 'center'}}>{selectedSkill}</Typography>
              </Box>
              <Box sx={{flex: '0 1 auto', justifyContent: 'flex-end'}}>
                <Typography variant="h6" sx={{textAlign: 'center', wordWrap: 'break-word', overflowWrap: 'break-word'}}>
                  Question {seriesState.currentQuestionIndex + 1} of {seriesState.totalQuestionsInSeries}
                </Typography>
              </Box>
              
            </Box>

            {/* Question display and controls */}
            <Box sx={{ mb: 4 }}>
              {questionText && renderLatexOrText(questionText)}
              
              {/* Conditionally render the answer options or the free-response form */}
              {questionText && choices.length === 0 ? (
                <form onSubmit={handleSubmit}>
                  <TextField fullWidth variant="outlined" placeholder="Enter your answer..."
                      value={freeResponseInput}
                      onChange={(e) => setFreeResponseInput(e.target.value)}
                      disabled={isAnswerSubmitted && feedback === 'Correct!'}
                      sx={{ mb: 2,'& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: feedback === 'Correct!' ? 'custom.green' : feedback ? 'custom.red' : undefined,
                          },
                        },
                      }}
                    />
                </form>
                ) : (
                <Grid container spacing={2}>
                  {choices.map((choice, index) => {
                    const choiceLetter = String.fromCharCode(65 + index);
                    const isSelected = selectedAnswer === choiceLetter;
                    const isIncorrect = incorrectAnswers.includes(choiceLetter);
                    const showCorrectAnswer = isAnswerSubmitted && choiceLetter === correctAnswer;
                    
                    return (
                      <Grid size={{xs:12, sm:6, md:4, lg:3}} key={index}>
                        <Button fullWidth variant={isSelected ? 'contained' : 'outlined'}
                        onClick={() => {
                          setSelectedAnswer(choiceLetter);
                          setIsAnswerSubmitted(false); // Reset the submitted state to allow new submissions
                        }}
                        disabled={isAnswerSubmitted && selectedAnswer === correctAnswer} // Disable if incorrect
                        sx={{
                          // Default state
                          bgcolor: 'background.paper',
                          '&:hover': {                    // <-- Default hover
                            bgcolor: 'background.paper',  // Keep same color on hover
                          },
                          // Selected but not submitted
                          ...(isSelected && !isAnswerSubmitted && {
                            bgcolor: 'custom.lightblue',
                            '&:hover': {
                              bgcolor: 'custom.lightblue',
                            },
                          }),
                          // Incorrect answer after submission
                          ...(isIncorrect && {
                            bgcolor: 'custom.red',
                            '&:hover': {
                              bgcolor: 'custom.red', // Prevent hover color change
                            },
                          }),
                          // Show correct answer only if submitted AND this was selected correctly
                          ...(showCorrectAnswer && selectedAnswer === correctAnswer && {
                            bgcolor: 'custom.green',
                            '&:hover': {
                              bgcolor: 'custom.green', // Prevent hover color change
                            },
                            '&.Mui-disabled': {
                              bgcolor: 'custom.green',
                              color: 'white',
                            },
                          }),

                        }}
                        >{renderLatexOrText(choice)}
                        </Button>
                      </Grid>
                    );
                  })}
                </Grid>
              )}  

              {/* Navigation buttons */}
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  disabled={seriesState.currentQuestionIndex === 0}
                  onClick={handlePreviousQuestion}
                  startIcon={<ArrowBackIcon />}
                > Previous Question
                </Button>
                
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Button variant="outlined" 
                    onClick={// If it's a milestone question and free response, handle like a submit
                      (choices.length === 0 && (seriesState.currentQuestionIndex + 1) % 5 === 0) ? 
                        handleSubmit :  handleSkip
                    } endIcon={<SkipNextIcon />}
                    // Disable skip if it's the last question of 5 or if correct answer was just submitted
                    disabled={// Only disable if:
                      // 1. Question is multiple choice (has choices) AND
                      // 2. Either:
                      //    - Answer is correct and submitted, OR
                      //    - It's a milestone question (5, 10, 15, etc.)
                      (choices.length > 0 && (
                        (isAnswerSubmitted && selectedAnswer === correctAnswer) || 
                        (seriesState.currentQuestionIndex + 1) % 5 === 0
                      ))}
                  > {(choices.length === 0 && (seriesState.currentQuestionIndex + 1) % 5 === 0) ? 
                    'Show Answer' : 
                    'Skip Question'
                    }
                  </Button>

                  <Button variant="contained" //handles both submit and next question once user gets it right
                    onClick={isAnswerSubmitted && feedback === 'Correct!' ? 
                      (seriesState.currentQuestionIndex + 1 >= seriesState.totalQuestionsInSeries ? 
                        handleExtendSeries : handleNextQuestion) : 
                      handleSubmit}
                  >
                    {isAnswerSubmitted && feedback === 'Correct!' ? 
                      (seriesState.currentQuestionIndex + 1 >= seriesState.totalQuestionsInSeries ? 
                        'Practice 5 More Questions?' : 'Next Question') : 
                      'Submit Answer'}
                  </Button>
                </Box>
              </Box>

          
              {choices.length === 0 ? 
                (isAnswerSubmitted && freeResponseInput.trim() === '' ? (
                  <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1,border: 1, borderColor: 'primary.main'}}>
                    <Typography variant="h6" sx={{ mb: 1, color: 'primary.main', fontWeight: 'bold'}}>{feedback}</Typography>
                    {/*<Typography variant="body1"> {feedback} </Typography>*/}
                  </Box>
                ) : (isAnswerSubmitted && 
                  <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1,border: 1, borderColor: 'primary.main'}}>
                    {/*In place of {feedback} below, I used to have "Explanation:" */}
                    <Typography variant="h6" sx={{ mb: 1, color: 'primary.main', fontWeight: 'bold'}}>{feedback}</Typography>
                    <Typography variant="body1"> {renderLatexOrText(explanationText)} </Typography>
                  </Box>
                )
              ) 
              : (isAnswerSubmitted && selectedAnswer === correctAnswer && feedback && (
                  <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper',
                      borderRadius: 1,border: 1, borderColor: 'primary.main',
                    }}
                    >
                    <Typography variant="h6" sx={{ mb: 1, color: 'primary.main', fontWeight: 'bold'}}>
                      Explanation:
                    </Typography>
                    <Typography variant="body1"> {renderLatexOrText(explanationText)} </Typography>
                  </Box>
                ))
              }
              

              {/*Backup which originally worked:
              {isAnswerSubmitted && selectedAnswer === correctAnswer && feedback && (
                <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper',
                    borderRadius: 1,border: 1, borderColor: 'primary.main',
                  }}
                  >
                  <Typography variant="h6" sx={{ mb: 1, color: 'primary.main', fontWeight: 'bold'}}>
                    Explanation:
                  </Typography>
                  <Typography variant="body1"> {renderLatexOrText(explanationText)} </Typography>
                </Box>
              )}       */}    

              
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default SkillButtonsAndQuestionGenerator;
