import React, { useState, useEffect, useContext, useRef } from 'react';
import { signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext';
import { Avatar, Button, Menu, MenuItem, TextField, Box, Alert, IconButton, ClickAwayListener } from '@mui/material';
import { AccountCircle, KeyboardArrowDown } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

type LoginProps = {
  onLoginSuccess: () => void;
  toggleDropdown: () => void;
  //dropdownRef: React.RefObject<HTMLDivElement>; //handled with buttonRef internally
  showLogin: boolean;
};

// Is this overkill?
const StyledLink = styled(RouterLink)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  display: 'block',
  marginBottom: theme.spacing(1),
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.primary.dark
  }
}));

const Login: React.FC<LoginProps> = ({ onLoginSuccess, toggleDropdown, showLogin } ) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null); // Add state to hold error messages
  const [success, setSuccess] = useState<string | null>(null); // State to hold success messages
  const navigate = useNavigate(); //initializes navigate
  const { user, initialLoading } = useContext(UserContext); //probably don't need initialLoading...or even loading?
  const buttonRef = useRef<HTMLButtonElement>(null);
  //const menuRef = useRef<HTMLDivElement>(null);

  //const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  /* When dropdown opens, tell Menu which element to anchor to because otherwise it won't know where to expand from
  useEffect(() => {
    if (showLogin) {
      const handleClickOutside = (event: MouseEvent) => {
        // Check if click is outside BOTH the button AND the menu
        const isOutsideButton = buttonRef.current && !buttonRef.current.contains(event.target as Node);
        const isOutsideMenu = menuRef.current && !menuRef.current.contains(event.target as Node);
        
        if (isOutsideButton && isOutsideMenu) {
          toggleDropdown();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [showLogin, toggleDropdown]);*/


  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Set persistence to maintain the session even after the user closes the browser
      await setPersistence(auth, browserLocalPersistence);
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      //const user = userCredential.user; //logs into Firestore to get database values of user data (progres on SAT, etc.)
      //Removed const user = userCredential.user because UserContext is where the user's data is loaded, and then shared via UserProvider
      //Login.tsx is just for authenticating email and password
      console.log('User logged in:', userCredential.user);

      setError(null); // Clear any previous errors if login is successful
      setSuccess('Login successful! Redirecting...'); // Show success message
      onLoginSuccess(); //Close dropdown after successful login

      //Added on 9/16/2024:
      //Load the user's data from Firestore
      const userDocRef = doc(db, 'users', userCredential.user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        console.log('User data:', userData);
        // You can now use userData in your app (e.g., set it in context or state). No need to manually update it since it has its own Listeners.
      } else {
        console.log('No such document!');
      }

      // Redirect the user to the homepage or a dashboard after successful login
      setTimeout(() => {
        navigate('/sat'); // Redirect after showing the success message
      }, 1500); // Wait 2 seconds before redirecting
    
      // Or immediately redirect the user to the homepage or a dashboard after successful login with this:
      //navigate('/sat'); // Or wherever you want to redirect the user after signing in

    } catch (error: any) {
      console.error('Error logging in:', error);

      // Set appropriate error messages based on the error type
      if (error.code === 'auth/wrong-password') {
        setError('Incorrect password. Please try again.');
      } else if (error.code === 'auth/user-not-found') {
        setError('No user found with this email. Please check your email address or register.');
      } else if (error.code === 'auth/invalid-email') {
        setError('The email address is formatted incorrectly. Please try again.');
      } else {
        setError('Failed to log in. Please try again later.');
      }

      setSuccess(null); // Clear the success message if there was an error
    }
  };

  //If you want to reference the user's name on the Login button, you can do: {getDisplayName(user.firstName)}
  return (
    <ClickAwayListener onClickAway={() => {
      if (showLogin) {
        toggleDropdown();
      }
    }}>
      <Box>
        {/* Login Button/Avatar initial when logged in */}
        {initialLoading ? (
          <Button variant="contained" disabled>
            Loading...
          </Button>
        ) : user ? (
          <IconButton 
            ref={buttonRef}
            onClick={toggleDropdown}
            sx={{ gap: 1 }}
          >
            <Avatar sx={{ bgcolor: 'primary.main' }}>
              {user.firstName?.[0]?.toUpperCase()}
            </Avatar>
            <KeyboardArrowDown />
          </IconButton>
        ) : (
          <Button ref={buttonRef} variant="contained" 
            onClick={toggleDropdown}
            startIcon={<AccountCircle />}
            sx={{ 
              bgcolor: 'palette.primary.main',
              '&:hover': { bgcolor: 'palette.primary.dark' }
            }}
          >
            Login
          </Button>
        )}

        <Menu
          anchorEl={buttonRef.current}
          open={showLogin}
          onClose={toggleDropdown}
          elevation={3}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Box component="form" onSubmit={handleLogin} sx={{ p: 2, width: 300 }}>
            {/* Error/Success Alerts */}
            {error && (<Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>)}
            {success && (<Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>)}

            {/* Form Fields */}
            <TextField fullWidth label="Email" type="email" value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal" required autoComplete="email"
            />
            <TextField fullWidth label="Password" type="password" value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal" required autoComplete="current-password"
            />

            {/* Submit Button */}
            <Button fullWidth type="submit" variant="contained" sx={{ mt: 2 }}>
              Sign In
            </Button>

            {/* Additional Links */}
            <Box sx={{ mt: 2, textAlign: 'center' }}>
            <StyledLink to="/create-profile" onClick={toggleDropdown}>
              Create Profile
            </StyledLink>
            {/*I don't have a forgot password feature yet, but I should figure that out soon.
            <StyledLink to="/forgot-password" onClick={toggleDropdown}>
              Forgot Password?
            </StyledLink>*/}
            </Box>
          </Box>
        </Menu>
      </Box>
    </ClickAwayListener>
  );
};

export default Login;
