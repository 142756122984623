import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    custom: {
      orange: string;
      darkOrange: string;
      lightGrey: string;
      darkGrey: string;
      green: string;
      darkgreen: string;
      red: string;
      lightblue: string;
    };
  }
  interface PaletteOptions {
    custom?: {
      orange: string;
      darkOrange: string;
      lightGrey: string;
      darkGrey: string;
      green: string;
      darkgreen: string;
      red: string;
      lightblue: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#f16522', // My orange
      dark: '#d1551c',
    },
    secondary: {
      main: '#007bff', // My blue
      dark: '#0056b3',
    },
    custom: {
      orange: '#f16522', //Not used - instead do primary.main
      darkOrange: '#d1551c', //Not used - instead use primary.dark
      lightGrey: '#e0e0e0',
      darkGrey: '#2e2e2e',
      green: '#4caf50',
      darkgreen: '#388e3c',
      red: '#f44336', //or #fd7272 for a light red, almost pink which doesn't feel so punishing
      lightblue: '#63d5fe'
    },
    background: {
      default: '#ffffff',
      paper: '#f9f9f9',
    },
    text: {
      primary: '#0A0A0A',
    }
  },
  typography: {
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    h1: {
      fontSize: '2em',
      color: '#f16522',
    },
    h4: {
      color: '#f16522',
    },
    h5: {
      color: '#f16522',
    },
    h6: {
      color: '#f16522',
    },
    body1: {
      fontSize: '1rem',       // 16px - your main body text
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875rem',   // 14px - secondary text
      lineHeight: 1.43,
    },
    bodyBig: {
      fontSize: '1.25rem',
      lineHeight: 1.5,
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          textTransform: 'none', // Prevents automatic uppercase
          fontWeight: 'bold'
        },
        contained: {
          backgroundColor: '#f16522',
          color: 'white',
          '&:hover': {
            backgroundColor: '#d1551c',
            transform: 'scale(1.05)',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          },
        },
        outlined: {
            backbroundColor: 'f9f9f9',
            color: '#0A0A0A',
            '&:hover': {
                backgroundColor: 'f0f0f0',
                transform: 'scale(1.05)',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            },
            border: '1px solid #ddd',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
        }
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
});

export default theme;