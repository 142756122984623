import React from 'react'; //{useState, useEffect}
//import ChatComponent from './ChatComponent';
import { useNavigate } from 'react-router-dom';
//import { getAuth, setPersistence, browserLocalPersistence, createUserWithEmailAndPassword } from 'firebase/auth';
//import { setDoc, doc } from 'firebase/firestore';
//import { auth, db } from '../firebase'; // importsinstance of db from firebase.ts. 
//import CreateProfile, { FormData as ProfileFormData} from './CreateProfile';
import './HomePage.css';
import { Box, Container,Typography, Button, List, ListItem, ListItemIcon, ListItemText, Paper, useTheme, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
//import StarIcon from '@mui/icons-material/Star';
import CheckIcon from '@mui/icons-material/Check';

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 

  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          backgroundImage: 'url(/images/MIT-lawn-background.jpg)', backgroundSize: 'cover',
          backgroundPosition: 'center', backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
          width: '100%', px: 0, py: 0, mx: 0,
          color: 'white',
          flexShrink: 0,
          minHeight: {xs: '110vh',sm: '100vh'},
          position: 'sticky',
          display: 'flex',
          alignItems: 'center',
          '&::before': {
            content: '""', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
          }
        }}
      >
        <Container disableGutters sx={{ position: 'relative', zIndex: 1}}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Grid sx={{size: {sx:12, md:8, lg:7}, textAlign: 'center'}}>
              <Typography  variant="h2" component="h1" color="white"
                sx={{ fontWeight: 'bold', mb: 2, fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }}}
              >
                Want to Achieve Your College Dreams?
              </Typography>
              <Typography variant="h5" color="white"
                sx={{ mb: 4, opacity: 0.9, fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.5rem' }}}
              >
                Learn smarter, not harder. Boost Your SAT Score with Personalized AI-Powered Tutoring today!
              </Typography>
              <Button variant="contained" color="secondary" size="large" onClick={() => navigate('/sat')}
                sx={{ py: 2, px: 4, fontSize: '1.1rem',}}
              >
                Start Your SAT Journey Now!
              </Button>
            </Grid>
          </Box>
        </Container>
      </Box>

        {/*Alternatives to "Start Your SAT Journey Now", which was original, from 1/13/2025 - Do A/B Testing with this!" 
          Start the Free Mini-Test/Diagnostic Test (Mentomind uses that, with this description: "Get fast insights, pinpoint skill gaps, and focus on what matters most"
          Play the Free Fun Quiz
        */}


      {/* Main Content Section */}
      <Container 
        disableGutters
        sx={{ py: 8 }}
      >
        <Typography variant="h3" component="h2" align="center" sx={{ mb: 2 }}>
          Guiding Your Path to SAT Excellence
        </Typography>

        {isMobile ? (
          // Mobile Layout
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {/* Image first on mobile */}
            <Box>
              <Box 
                component="img" 
                src="/images/thrilled-student.JPG" 
                alt="Student celebrating SAT success"
                sx={{ width: '100%', height: 'auto', borderRadius: 2, boxShadow: 3 }}
              />
            </Box>
            
            {/* Content section */}
            <Paper elevation={4} sx={{ p: 2 }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                We're here to make SAT prep easy and effective to get into your dream schools 
                with scholarships!
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                We break down the test by each question type so you can drill what is most 
                important to you and get instant feedback and strategies to improve your SAT score!
              </Typography>
              <List>
                {[
                  'Review essential math knowledge and strategies to ace the math section',
                  'Learn the most common grammar rules to reduce mistakes',
                  'Increase your reading speed and ability to understand complex passages',
                  'Develop learning habits that will help you in college and beyond!'
                ].map((text, index) => (
                  <ListItem key={index} sx={{ py: 1 }}>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Box>
        ) : (

          <Box display="flex" flexDirection="row" gap={3} alignItems="center">
            <Box sx={{ flex: 5}}>
              {/* Content section */}
              <Paper elevation={4} sx={{ p: { xs: 2 } }}>
                <Typography sx={{ fontSize: '1.4rem', lineHeight: 1.6 }} variant="body1">
                  Get into your dream schools with scholarships with easy and effective SAT prep!
                  <br/>
                  We break down the test by each question type so you get instant feedback and strategies to improve your SAT score!
                </Typography>
                <List>
                  {[
                    'Review essential math knowledge and strategies to ace the math section',
                    'Learn the most common grammar rules to reduce mistakes',
                    'Develop learning habits that will help you in college and beyond!'
                  ].map((text, index) => (
                    <ListItem key={index} sx={{ py: 1 }}>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText 
                        primary={
                          <Typography sx={{ fontSize: '1.25em !important', lineHeight: 1.6 }}>
                            {text}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Box>
            <Box sx={{ flex: 4 }}>
              {/* Image section removed: borderRadius: 2, boxShadow: 3 */}
              <Box component="img" src="/images/thrilled-student.JPG" alt="Student celebrating SAT success"
                sx={{ width: '100%', height: 'auto', borderRadius: 2, boxShadow: 3}}
              />
            </Box>
          </Box>
        )}
      </Container>

      {/* Testimonials Section */}
      <Box sx={{ backgroundColor: theme.palette.primary.main, py: 8, color: 'white' }}>
        <Container>
          <Typography variant="h3" component="h2" align="center" sx={{ mb: 2, color: 'white' }}>
            What Our Students Say
          </Typography>
          <Grid display="flex" gap={3} flexDirection="row" spacing={4}>
            {[
              {
                quote: "This course was super helpful. My SAT score went up 220 points!",
                author: "Anna S., 2023 former student"
              },
              {
                quote: "The practice questions are exactly like the real SAT. I felt completely prepared on test day.",
                author: "Matthew B., 2024 former student"
              }
            ].map((testimonial, index) => (
              <Grid sx={{size: {sm: 12, md:4, lg:3}}} key={index}>
                <Paper sx={{ 
                  p: 3, height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  backdropFilter: 'blur(10px)', color: 'white'
                }}>
                  <Typography variant="body1" color="white" sx={{ mb: 2, fontStyle: 'italic', fontSize: '1.4rem', lineHeight: 1.6 }}>"{testimonial.quote}"</Typography>
                  <Typography variant="subtitle2" color="white">  - {testimonial.author}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Services Section */}
      <Container sx={{ py: 8 }}>
        <Typography variant="h3" component="h2" align="center" sx={{ mb: 6 }}>
          Choose Your Study Path
        </Typography>
        <Box sx={{display: 'grid', gridTemplateColumns: {xs: '1fr', sm:'repeat(3, 1fr)'}, 
        gap: {xs: 2, md: 3}, width: '100%', px: {xs: 2, sm: 3, md: 4}}}>
          {[
            {
              title: "Free Access",
              price: "Free",
              features: [
                "Access to SAT practice questions",
                "Basic AI feedback on answers",
                "Limited daily practice sessions",
                "No progress tracking over multiple session"
              ],
              buttonText: "Start Free",
              buttonVariant: 'outlined' as const
            },
            {
              title: "Premium Learning",
              price: "$20/month",
              features: [
                "Everything in Free Access plus...",
                "Unlimited practice questions",
                "Personalized AI coaching based on your profile",
                "Track progress with customized account",
              ],
              buttonText: "Get Premium",
              buttonVariant: 'contained' as const,
              highlighted: true
            },
            {
              title: "Ultimate Success (Coming Soon)",
              price: "$30/month",
              features: [
                "Everything in Premium Learning plus...",
                "Voice interaction with AI tutor (Speech-to-Text & Text-to-Speech)",
                "Personalized analytics dashboard and study recommendations",
              ],
              buttonText: "Get Ultimate",
              buttonVariant: 'contained' as const
            }
          ].map((tier, index) => (
            <Grid sx={{size:{sx:12, md:4}}} key={index}>
              <Paper sx={{ 
                p: 4, height: '100%', display: 'flex', flexDirection: 'column',
                ...(tier.highlighted && {
                  border: `2px solid ${theme.palette.primary.main}`,
                  boxShadow: `0 4px 20px ${theme.palette.primary.main}40`
                })
              }}>
                <Typography variant="h4" component="h3" align="center" sx={{ mb: 2 }}>
                  {tier.title}
                </Typography>
                <Typography variant="h3" component="p" align="center">
                  {tier.price}
                </Typography>
                <List sx={{ flexGrow: 1 }}>
                  {tier.features.map((feature, featureIndex) => (
                    <ListItem key={featureIndex} sx={{ py: 1 }}>
                      <ListItemIcon>
                        <CheckIcon color={tier.highlighted ? "primary" : "success"} />
                      </ListItemIcon>
                      <ListItemText primary={feature} />
                    </ListItem>
                  ))}
                </List>
                <Button fullWidth variant={tier.buttonVariant} color={tier.highlighted ? "primary" : "secondary"}
                  onClick={() => {
                    if (tier.price === "Free") {
                      navigate('/sat');
                    } else {
                      // For paid tiers, redirect to Stripe checkout
                      window.open("https://buy.stripe.com/28odU7b1M1seaLmaEE",
                        "_blank", "noopener,noreferrer"
                      )
                      //window.location.href = "https://buy.stripe.com/test_28o7sugb60QddJ65kk?locale=en&__embed_source=buy_btn_1PspjKP4YfvUie3H8KOSLqkn";
                    }
                  }}
                >
                  {tier.buttonText}
                </Button>
              </Paper>
            </Grid>
          ))}
        </Box>
      </Container>

      

    </Box>
  );
};

export default HomePage;