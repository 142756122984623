import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Mic, Pause, MicOff } from 'lucide-react';
import { Alert, AlertDescription } from './ui/alert';
import { IconButton } from '@mui/material';
import { useContext } from 'react';
import { UserContext } from './UserContext'; // adjust path as needed

interface SpeechToTextProps {
  onTranscriptionUpdate: (text: string) => void;
  isDisabled?: boolean;
}

interface DeepgramResponse {
  channel: {
    alternatives: [{
      transcript: string;
    }];
  };
}

export const SpeechToTextComponent: React.FC<SpeechToTextProps> = ({onTranscriptionUpdate, isDisabled = false}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const websocketRef = useRef<WebSocket | null>(null);
  const streamRef = useRef<MediaStream | null>(null); // Reference for the microphone stream
  const timeoutRef = useRef<NodeJS.Timeout | null>(null); // Store the timer reference
  //connectionStatus is for UI formatting, but websocketRef.current?.readyState is better for actually checking since it's on the object itself.
  const [connectionStatus, setConnectionStatus] = useState<'connecting' | 'connected' | 'disconnected' | 'error'>('disconnected');
  const { user } = useContext(UserContext);
  
  
  
  const stopRecording = useCallback(() => {
    //Stop the Media Recorder = microphone on the browser
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {//Unnecessarily specific? Remove the ==='recording' check, or no? Don't think it hurts.
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current = null; // Clear reference to encourage garbage collection - might be superfluous, but better safe than sorry
      console.log("MediaRecorder microphone recording stopped.");
    }
    //Close the WebSocket connection = close the highway. Setting to null cleans up the Event Listeners from memory; closing the WebSocket by itself wouldn't clean them up, and this helps in case of an error. 
    if (websocketRef.current) {
        websocketRef.current.onopen = null;
        websocketRef.current.onmessage = null;
        websocketRef.current.onerror = null;
        websocketRef.current.onclose = null;
        websocketRef.current.close();
        websocketRef.current = null; // Clear the reference
        console.log("WebSocket connection closed and handlers cleared.");
    }
    //Stop the Media Stream = stops the transmission of audio to the Azure server = Stop sending cars down the highway
    if (streamRef.current){
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null; // Clear the reference
      console.log("Media Stream stopped.");
    }
    // Clear the maximum duration (60 seconds) timeout if it exists
    if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

    setIsRecording(false);
    setError(null); //resets an error if there was one.
  }, []);

  const startRecording = useCallback(async () => {
    try {
      setIsRecording(true); // Indicate recording is active
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream; // Store microphone stream
  
      // WebSocket connection to Deepgram. Add a user ID here to track their specific usage?
      const deepgramUrl = `wss://api.deepgram.com/v1/listen`;
      if(!process.env.REACT_APP_DEEPGRAM_API_KEY) return; //satisfies the type checking after this
  
      //websocketRef.current = new WebSocket(deepgramUrl);
      websocketRef.current = new WebSocket(deepgramUrl, ['token', process.env.REACT_APP_DEEPGRAM_API_KEY]);
  
      websocketRef.current.onopen = () => {
        console.log("WebSocket connected to Deepgram.");
        setConnectionStatus('connected');
        // readyState values: 0: CONNECTING. 1: OPEN. 2: CLOSING. 3: CLOSED
  
        // Initialize MediaRecorder
        mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'audio/webm; codecs=opus' });
  
        // Send audio data chunks to Deepgram
        mediaRecorderRef.current.ondataavailable = async (event) => {
          if (event.data.size > 0 && websocketRef.current?.readyState === WebSocket.OPEN) {
            const audioData = await event.data.arrayBuffer();
            websocketRef.current.send(audioData);
            console.log("Audio data chunk sent to Deepgram.");
          }
        };
  
        mediaRecorderRef.current.start(250); // Send audio chunks every 250ms
      };
  
      // Process Deepgram transcription results, including sanitizing to ensure proper format
      //Not sanitizing would remove the "ifs" and have const response: DeepgramResponse = JSON.parse(message.data), then const transcript = response.channel.alternatives[0].transcript
      websocketRef.current.onmessage = (message) => {
        try {
          console.log(`Raw message received in onmessage: ${message.data}`);
          const data = JSON.parse(message.data);
          console.log(`Data received and parsed: ${data.channel.alternatives[0]?.transcript}`)
      
          if (typeof data?.channel === "object" && Array.isArray(data.channel.alternatives)
            && typeof data.channel.alternatives[0]?.transcript === "string") //ensures response matches the DeepgramResponse data type
            {
            const transcript = data.channel.alternatives[0].transcript.trim();
            if (transcript) {
              onTranscriptionUpdate(transcript);
              console.log(`Transcription from Deepgram: ${transcript}`);
            }
          } else {
            throw new Error("Invalid response structure");
          }
        } catch (error) {
          console.error("Error parsing transcription response:", error);
        }
      };
      
  
      websocketRef.current.onerror = (event) => {
        console.error("WebSocket error:", event);
        setConnectionStatus('error');
        setError("An error occurred with the transcription service.");
        stopRecording();
      };
  
      websocketRef.current.onclose = (event) => {
        console.log(`WebSocket closed:", Code=${event.code}, Reason=${event.reason}, Clean=${event.wasClean}`);
        setConnectionStatus('disconnected');
        setIsRecording(false);
      };

      // Set a timer to stop the recording after 60 seconds, limiting accidental recording or API abuse
      timeoutRef.current = setTimeout(() => {
        console.log("Maximum recording duration reached. Stopping recording.");
        stopRecording();
      }, 60000); // 60 seconds
  
    } catch (error) {
      console.error("Error starting recording:", error);
      setError("Could not access the microphone.");
      stopRecording();
    }
  }, [onTranscriptionUpdate, stopRecording]);
  

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      stopRecording();
    };
  }, [stopRecording]);


  const toggleRecording = useCallback(() => {
      if (isRecording) {
        stopRecording();
      } else {
        startRecording();
      }
    }, [isRecording, startRecording, stopRecording]);


  return (
    <>
      <IconButton
        type="button"
        onClick={toggleRecording}
        disabled={isDisabled}
        className="speech-to-text-button"
        aria-label={isRecording ? 'Stop recording' : 'Start recording'}
        sx={{
          width: 48, // No need for px units in sx
          height: 48,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 0, // Use MUI's spacing shortcuts
          border: 'none',
          bgcolor: 'primary.main',
          '&:hover': {
            bgcolor: 'primary.dark', // Uses theme colors
          },
          transition: 'background-color 0.2s', // Smooth hover effect
        }}
      >
        {isDisabled ? <MicOff /> : isRecording ? <Pause /> : <Mic />}
      </IconButton>
      
      {error && (
        <Alert variant="destructive" className="mt-2">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
    </>
  );
};

export default SpeechToTextComponent;