import React, {useState} from 'react';
import { Box, Typography, useTheme, useMediaQuery, IconButton, Tooltip } from '@mui/material'; //styled
//removed Container from mui/material import because the default padding messes with hero images
//import Grid from '@mui/material/Grid2';
import ButtonChoice from './ButtonChoice';
import { PromptProvider } from './PromptContext';
import PersonalizedBar from './PersonalizedBar';
import './SecondPage.css';
//import ProgressBar from './ProgressBar';

import ChatComponent from './ChatComponent';
import Split from 'react-split';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { styled } from '@mui/material/styles';

// Add new styled components for the split layout
const StyledSplitContainer = styled(Split)(({ theme }) => ({
    display: 'flex', height: '100%', paddingLeft: '3vw', paddingRight: '1.5vw',
    /* Style for the second panel (chat container)
    '& > *:last-child': {
        position: 'relative', // Ensure positioning context
        display: 'flex',
        minHeight: '100%',
        // Prevent content from collapsing when chat is hidden
        minWidth: '30px',
    },*/
    //Style for draggable divider (called gutter)
    '& .gutter': {background: 'transparent', position: 'relative',
      '&:hover': {
        '&::after': {
          transform: 'scaleX(1.5)',
        },
      },
      //Create visible divider line
      '&::after': {content: '""', position: 'absolute', left: '50%', top: 0, bottom: 0,
        width: '4px', transform: 'translateX(-50%)',
        background: theme.palette.custom.lightGrey,
        transition: 'transform 0.2s ease',
      },
    }, //makes cursor change to col-resize when hovering over the gutter
        '& .gutter-horizontal': {cursor: 'col-resize'},
    // Add snap points      
    '& > *': {transition: 'flex-basis 0.1s ease-out',}
  }));
  
  const ToggleButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: '-20px',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1000,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  }));


const SecondPage = () => {
    const theme = useTheme();
    //const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));
    //const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // State for chat panel size and visibility
    const [chatSize, setChatSize] = useState(30);  // Initial size 30%
    const [isChatVisible, setIsChatVisible] = useState(true);
    const [lastChatSize, setLastChatSize] = useState(30);  // Remember last size when toggling

    // Toggle chat visibility
    const toggleChat = () => {
        if (isChatVisible) {
        setLastChatSize(chatSize);  // Store current size
        setIsChatVisible(false);
        } else {
        setIsChatVisible(true);
        setChatSize(lastChatSize);  // Restore previous size
        }
    };
    
    const StyledHeroSection = styled('section')(({ theme }) => ({
        backgroundImage: 'url(/images/picture.jpg)', // ✅ Correct
        backgroundSize: 'cover',
        backgroundPosition: 'center 145%', //x value first, y value second
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        height: '35vh', // Adjust as needed
        minHeight: '25vh',
        width: '100%',
        position: 'sticky',
        flexShrink: 0,

        '&::after': { //Adds a gradient overlay to the hero section
          content: '""',
          position: 'absolute',
          top: 0, left: 0, right: 0, bottom: 0,
          width: '100%',
          background: 'linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0))',
        },
    }));
      
    
    return (
        <Box sx={{ flex: 1, minheight: '100vh', display: 'flex', 
            flexDirection: 'column'}}> 
            <StyledHeroSection/>
            
            <PromptProvider>
                <PersonalizedBar />
                {isMobile ? ( 
                    // Mobile layout - stack components vertically
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2, padding: '0 3vw'}}>
                        {/*<Typography variant="h4" component="h1" align="center" sx={{ mb: 2 }}>
                            Start by selecting a subject then a skill type to start learning now, then use the questions below to </Typography>*/}
                        <ButtonChoice />
                        {isChatVisible && <ChatComponent />}
                    </Box>
                ) : (
                    // Desktop layout - side-by-side with draggable divider. 
                    //The two elements which come after are the two children of the StyledSplitContainer.
                        <StyledSplitContainer 
                            sizes={isChatVisible ? [100 - chatSize, chatSize] : [100, 0]} //initial sizes but changeable
                            minSize={[45, 0]} //Minimum 45% for questions, no minimum for chat
                            maxSize={[window.innerWidth * 0.75, window.innerWidth * 0.50]} //Maximum 85% for questions, 55% for chat
                            gutterSize={20} // Size of the divider total width, not line itself
                            //snapOffset={0} // Snap to 10px from the edge
                            dragInterval={1} // Snap to 1% increments
                            onDrag={(sizes) => {setChatSize(sizes[1]);}}
                        >
                        <Box sx={{ position: 'relative', height: '100%'}}>
                            <ButtonChoice />
                        </Box>
                        
                        <Box sx={{position: 'relative', height: '100%', minWidth: '30px', 
                        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',
                        }}
                        >
                            {isChatVisible && <ChatComponent />}
                            <Tooltip title={isChatVisible ? "Hide AI Chat" : "Show AI Chat"}>
                                <ToggleButton sx={{top: '5vh' }} onClick={toggleChat}>
                                    {isChatVisible ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                                </ToggleButton>
                            </Tooltip>
                        </Box>
                    </StyledSplitContainer>
                )}
                
            </PromptProvider>
                
    </Box>    
  );
};
export default SecondPage;

/*
<Grid container spacing={3} sx={{ flex: 1 }}>
                    {/* Left section - Skills and Questions }
                    {/*size means how many columns (out of 12) to take up. XS is 1 column. }
                    <Grid component="div" size={{xs: 12, md: 8, lg: 9}}>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, height: '100%'}}>  
                            <ButtonChoice />
                            <Box sx={{flex: 1, borderRadius: 1, p: 2, boxShadow: 1}}>
                            
                                {/* Question display area }
                                <ProgressBar />
                                {/* Question content will go here }
                            </Box>
                        </Box>
                    </Grid>

                    {/* Right section - Chat }
                    <Grid component="div" size={{xs: 12, md: 4, lg: 3}}>
                        <Box sx={{height: '100%', bgcolor: 'background.paper', borderRadius: 1, p: 2, boxShadow: 1}}>
                        <ChatComponent />
                        </Box>
                    </Grid>
                </Grid>
*/



/*
      <section className = "hero-second-page">
                        
                </section>


In place of the current <Grid container spaceing...> </Grid>
            <div className="sat-area">
                    <div className="skills-and-questions-left-section">
                        <ButtonChoice />
                    </div>
                    <div className="chat-component-right-section">
                        <ChatComponent />
                    </div>
                </div>
                <ProgressBar />


      */

