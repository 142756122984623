// UserContext.tsx
import React, { createContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase'; // Adjust the import paths as needed
import { onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore'; //getDoc used to be imported too but isn't used now.

export type SkillFrequency = {
  'Function of Sentence': number;
  'Inferences': number;
  'Main Idea': number;
  'Pronouns and Modifiers': number;
  'Punctuation': number;
  'Referencing Data': number;
  'Supporting Claims': number;
  'Synthesizing Notes': number;
  'Tenses': number;
  'Transition Words': number;
  'Two Passages': number;
  'Word Choice': number;
  'Absolute Value': number;
  'Algebra': number;
  'Circles': number;
  'Exponential Equations': number;
  'Exponential Word Problems': number;
  'Geometry': number;
  'Interpreting Graphs': number;
  'Linear Equations': number;
  'Linear Word Problems': number;
  'Inequality Word Problems': number;
  'Percent': number;
  'Polynomial Expressions': number;
  'Probability': number;
  'Quadratic Equations': number;
  'Statistics': number;
  'Systems of Equations': number;
  'Trigonometry': number;
  'Unit Conversions': number;
}

export const skillFrequency: SkillFrequency = {
  'Function of Sentence': .02,
  'Inferences': .05,
  'Main Idea': .054,
  'Pronouns and Modifiers': .023,
  'Punctuation': .084,
  'Referencing Data': .027,
  'Supporting Claims': .047,
  'Synthesizing Notes': .068,
  'Tenses': .036,
  'Transition Words': .061,
  'Two Passages': .009,
  'Word Choice': .099,
  'Absolute Value': .002,
  'Algebra': .027,
  'Circles': .011,
  'Exponential Equations': .009,
  'Exponential Word Problems': .014,
  'Geometry': .059,
  'Interpreting Graphs': .029,
  'Linear Equations': .045,
  'Linear Word Problems': .059,
  'Inequality Word Problems': .011,
  'Percent': .018,
  'Polynomial Expressions': .016,
  'Probability': .009,
  'Quadratic Equations': .047,
  'Statistics': .014,
  'Systems of Equations': .029,
  'Trigonometry': .014,
  'Unit Conversions': .009
}

export type UserData = {
  uid: string;
  email: string;
  firstName: string;
  lastName: string;
  baseSubscription: boolean;
  premiumSubscription: boolean;
  hasTakenSAT: boolean;
  hasTakenACT: boolean;
  previousSATScores: { math: string; readingWriting: string };
  previousACTScores: { math: string; reading: string; writing: string; science: string };
  hasUpcomingTest: boolean;
  testDate: string;
  targetScores: { math: string; readingWriting: string };
  colleges: string;
  intendedMajor: string;
  strengths: string;
  areasToImprove: string;
  motivation: string;
  feedbackStyle: string;
  personalInterests: string;
  grandTotalQuestionsAttempted: number;
  grandTotalQuestionsCorrect: number;
  completedSATTests: number;
  totalDaysPracticed: number;
  numberOfDaysInAStreak: number;
  lastPracticedDate: string | null; //used to calculate totalDaysPracticed and numberOfDaysInAStreak

  //Should activeChallenges be string so it's like an open-ended quest/activity, or type [string, number] or [Skills, number]?
  //If you change here, must change in CreateProfile too!
  activeChallenges: string[];
  completedChallenges: string[];
  // ... include other fields as needed
  skills: {
    [skillName: string]: {
      currentTestQuestionsAttempted: number;
      currentTestQuestionsCorrect: number;
      currentTestEasyQuestionsAttempted: number;
      currentTestEasyQuestionsCorrect: number;
      easyQuestionsIndex: number;
      currentTestMediumQuestionsAttempted: number;
      currentTestMediumQuestionsCorrect: number;
      mediumQuestionsIndex: number;
      currentTestHardQuestionsAttempted: number;
      currentTestHardQuestionsCorrect: number;
      hardQuestionsIndex: number;
      overallQuestionsAttempted: number;
      overallQuestionsCorrect: number;
    };
  };
};

//Type used in SkillButtons...QGenerator to update both user, and session data (if no user)
export type SkillData = UserData['skills'][string]; //key name for each skill (I think)
export type Skills = UserData['skills']; //object containing all skills (I think)

type UserContextType = {
  user: UserData | null;
  loading: boolean;
  initialLoading: boolean;
  error: Error | null;
  updateUserData: (data: Partial<UserData>) => Promise<void>;
};

export const UserContext = createContext<UserContextType>({
  user: null,
  loading: true,
  initialLoading: true,
  error: null,
  updateUserData: async () => {
    throw new Error("updateUserData function must be provided by the UserProvider");
  },
});

export const categories = {
  "Reading and Writing": [
    "Function of Sentence", "Inferences", "Main Idea", "Pronouns and Modifiers",
    "Punctuation", "Referencing Data", "Supporting Claims", "Synthesizing Notes",
    "Tenses", "Transition Words", "Two Passages", "Word Choice"
  ],
  "Math": [
    "Absolute Value", "Algebra", "Circles", "Exponential Equations",
    "Exponential Word Problems", "Geometry", "Interpreting Graphs", "Linear Equations",
    "Linear Word Problems", "Inequality Word Problems", "Percent", "Polynomial Expressions",
    "Probability", "Quadratic Equations", "Statistics", "Systems of Equations",
    "Trigonometry", "Unit Conversions"
  ]
} as const;

export type CategoryName = keyof typeof categories; // "Reading and Writing" | "Math"
export type SkillName = typeof categories[CategoryName][number]; // Individual skill names

// Function to extract all skills from categories
export const getAllSkills = (): string[] => {
  return Object.values(categories).flat();
};

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<UserData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [initialLoading, setInitialLoading] = useState<boolean>(true); // NEW: Tracks initial loading


  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (firebaseUser) => { //onAuthStateChanged is a listener; unsubscribeAuth unmounts it
      if (firebaseUser) {
        const uid = firebaseUser.uid;
        const userDocRef = doc(db, 'users', uid);

        const unsubscribeUserDoc = onSnapshot(
          userDocRef,
          (docSnap) => {
            if (docSnap.exists()) {
              const userData = { uid, ...docSnap.data() } as UserData;
              setUser(userData);
              setLoading(false);
            } else {
              setError(new Error('User data not found'));
              setLoading(false);
            }
            setInitialLoading(false); // Stop initial loading regardless of the result
          },
          (error) => {
            setError(error);
            setLoading(false);
            setInitialLoading(false); // Stop initial loading if an error occurs
          }
        );

        return () => {
          unsubscribeUserDoc();
        };
      } else {
        setUser(null);
        setLoading(false);
        setInitialLoading(false); // Stop initial loading if no user is logged in
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  const updateUserData = async (data: Partial<UserData>) => {
    if (!user) return;
    const userDocRef = doc(db, 'users', user.uid);
    try {
      await updateDoc(userDocRef, data);

      // Manually update the user state to reflect changes immediately - do I need this?
    setUser((prevUser) => (prevUser ? { ...prevUser, ...data } : prevUser));

    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };

  //Prior to 8 am on 11/15, instead of {initialLoading...}, there was just {children}
  return (
    <UserContext.Provider value={{ user, loading, initialLoading, error, updateUserData }}>
      {children} {/* Render loading screen during initial load */}
    </UserContext.Provider>
  );
};
