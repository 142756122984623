import React, { useContext, useState } from 'react';
import { UserContext } from './UserContext'; // Contains all user data to add firstName, Questions Answered, etc.
import theme from '../muiTheme';
import { styled } from '@mui/material/styles';
import { Accordion, AccordionSummary, AccordionDetails, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ExpandedDashboard } from './dashboard-components/expanded-dashboard'; // Import the ExpandedDashboard component



/* ********************************** Future Development Idea **********************************
This could be a dropdown bar, with the current content as a high level summary, and the dropdown
content being more detailed information.

That dropdown could include the detailed dashbaord:
1. Suggested next skills to practice (3-5 in order of commonality on test + user's performance (weakest skill))
--> I need to link the Challenge Buttons to go straight into the practice inside SkillButtonsAndQuestionGenerator.tsx
2. Progress on puzzle/beautiful picture which is slowly unlocked with more correct answers of each skill
3. Line graph of estimated SAT score based on user's performance on each skill, showing improvement over time
*/

// Styled Accordion component with hover effect
const StyledAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: '#2e2e2e',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
    // Remove the hover effect from the main Accordion
    '& .MuiAccordionSummary-root': {
      padding: '10px 20px',
      minHeight: '64px',
      /*1/15/2025: Temporarily removed hover effect from the main Accordion while the expanded-dashboard is in development
      '&:hover': {
        backgroundColor: '#3a3a3a',
        transform: 'scale(1.01)',
        transition: 'all 0.3s ease-in-out',
      },*/
      '& .MuiAccordionSummary-content': {
        margin: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      },
      '& p': {
        margin: 0,
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#ffffff',
      },
    }, /* 1/15/2025: Temporarily removed, but put back when finisihed with the expanded-dashboard!!
    '& .MuiAccordionSummary-expandIconWrapper': { //does this need to be removed too?
      color: theme.palette.primary.main,
      '& .MuiSvgIcon-root': {
        fontSize: '2rem',
      },
    },*/
}));


const PersonalizedBar: React.FC = () => {
    const { user } = useContext(UserContext); //Access Firestore's user data in the "user" object, when they are logged in
    //Used to load loading, error from useContext, but they weren't used for anything - should they be?
    // State for toggling between Practice and Test Mode
    const [isPracticeMode, setIsPracticeMode] = useState(true);
    //const [isDashboardVisible, setIsDashboardVisible] = useState<boolean>(false); // will be obsolete when we implement the dropdown bar
    const [expanded, setExpanded] = useState<boolean>(false); // State to manage dashboard visibility

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    //Toggle between Practice and Test Mode - functionality not built out yet but should be!
    const toggleMode = () => {
        setIsPracticeMode(!isPracticeMode);
    };
    /*
    const toggleDashboard = () => {
        setIsDashboardVisible(!isDashboardVisible); // Toggle the visibility state
    };*/

    const handleChange = (_event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded);
    };

    return (
        <StyledAccordion
            expanded={expanded}
            onChange={handleChange}
            slotProps={{ transition: { timeout: 300 } }} // Animation duration
        >
            <AccordionSummary 
                //1/15/2025: Temporarily removed, but put back when finisihed!! expandIcon={<ExpandMoreIcon />}
                aria-controls="personalized-content"
                id="personalized-header"
            >
                {isMobile ? (
                    <>
                        <p>Days Practiced: {user ? user.totalDaysPracticed : 1}</p>
                        <p>{user ? `Questions Completed: ${user.grandTotalQuestionsAttempted || 0}` : `Create Profile to track progress!`}</p>
                    </>
                ) : (
                    <>
                        <p>Welcome{user ? `, ${user.firstName}!` : "!"}</p>
                        <p>Days Practiced: {user ? user.totalDaysPracticed : 1}</p>
                        <p>{user ? `Questions Completed: ${user.grandTotalQuestionsAttempted || 0}` : `Create a Profile to track your progress!`}</p>
                    </>
                )}
            </AccordionSummary>
            
            {/*} Temporariliy removed while this is in development
            <AccordionDetails>
                <ExpandedDashboard />
            </AccordionDetails>
            */}
        </StyledAccordion>
    );

    /*
    return (
        <div>
            {isMobile ? (
                <div className="personalized-bar" style={{paddingLeft: '5vw', paddingRight: '5vw'}}>
                    <p>Days Practiced: {user ? user.totalDaysPracticed : 1}</p>
                    <p>{user ? `Questions Completed: ${user.grandTotalQuestionsAttempted || 0}` : `Create Profile to track progress!`}</p>
                </div>
            ) : (
                <div className="personalized-bar" style={{paddingLeft: '5vw', paddingRight: '5vw'}}>
                    <p>Welcome{user ? `, ${user.firstName}!` : "!"}</p>
                    <p>Days Practiced: {user ? user.totalDaysPracticed : 1}</p>
                    <p>{user ? `Questions Completed: ${user.grandTotalQuestionsAttempted || 0}` : `Create a Profile to track your progress!`}</p>
                </div>
            )}
            <button onClick={toggleDashboard} style={{ marginTop: '10px', padding: '10px', cursor: 'pointer' }}>
                {isDashboardVisible ? 'Hide Dashboard' : 'Show Dashboard'}
            </button>
            {isDashboardVisible && <ExpandedDashboard />} 
        </div>
    );
    */
};

export default PersonalizedBar;

/* Toggle Button 
            <div className="mode-toggle">
                <button
                    className={`toggle-btn ${isPracticeMode ? 'active' : ''}`}
                    onClick={toggleMode}
                >
                    Practice Mode
                </button>
                <button
                    className={`toggle-btn ${!isPracticeMode ? 'active' : ''}`}
                    onClick={toggleMode}
                >
                    Test Mode
                </button> 
            </div>*/
