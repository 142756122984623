// ButtonChoice.tsx
import React, { useState } from 'react';
//import SkillButtons from './SkillButtons';
import SkillButtonsAndQuestionGenerator from './SkillButtonsAndQuestionGenerator';
import { Box, Button, Typography, useTheme } from '@mui/material';


type MainMenuProps = {
  //define props here
}

const MainMenu: React.FC<MainMenuProps> = () => {
  const [selectedSubject, setSelectedSubject] = useState<string | null>("Reading and Writing"); //Default value is Reading and Writing now; before 1/13/2025 it was null
  //const theme = useTheme();
  const subjectList: string[] = ['Reading and Writing', 'Math'];

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, p: 1, backgroundColor: 'white'}}>
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: {xs: 'column', sm: 'row'}, gap: 2}}>
        <Typography variant="h4">Select a Subject and Skill: </Typography>
        <Box sx={{display: 'flex', gap: 2, flexDirection: 'row', justifyContent: 'space-evenly'}}>
          {subjectList.map((subject) => 
            <Button key={subject} variant={selectedSubject === subject ? 'contained' : 'outlined'}
            onClick={() => setSelectedSubject(subject)}
            sx={{minWidth: 160, height: 48, borderRadius: 2,
              backgroundColor: selectedSubject === subject ? 'custom.green' : undefined,
              '&:hover': {
                backgroundColor: selectedSubject === subject ? 'custom.darkgreen' : undefined,
              }
            }}>
              {subject}
            </Button>
        )}
        </Box>
      </Box>
      
      {selectedSubject && (<SkillButtonsAndQuestionGenerator selectedSubject={selectedSubject}/>)}

    </Box>
  );
}; /* the <SkillButtonsAndQuestionGenerator... /> component is the one that actually generates the questions */

export default MainMenu;