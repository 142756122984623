import React, {FC, useState, useEffect, useRef} from 'react'; //useContext
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './index.css';
import './AppStyles.css';
import HomePage from './components/HomePage';
import SecondPage from './components/SecondPage';
import Login from './components/Login';
import { UserProvider } from './components/UserContext'; //shares user data from Firestore throughout Components.
import CreateProfile from './components/CreateProfile';
import { ThemeProvider } from '@mui/material/styles'; //Material UI Styline
import CssBaseline from '@mui/material/CssBaseline';
import theme from './muiTheme';
import { Box,  Typography,AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, ListItemIcon, useMediaQuery, Button} from '@mui/material'; 
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Link as RouterLink } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';


const App: FC = () => {
  const [showLogin, setShowLogin] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null); // Create a ref for the dropdown
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false); //open mobile sandwich menu to navigate website on phones

  // Navigation items
  const navItems = [
    { text: 'Home', path: '/', icon: <HomeIcon /> },
    { text: 'SAT Practice', path: '/sat', icon: <SchoolIcon /> },
    { text: 'Create Profile', path: '/create-profile', icon: <PersonAddIcon /> },
  ];
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Drawer content
  const drawer = (
    <List>
      {navItems.map((item) => (
        <ListItem key={item.text} component={RouterLink} 
          to={item.path} onClick={() => setMobileOpen(false)}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItem>
      ))}
    </List>
  );
  
  const toggleDropdown = () => {
    setShowLogin(!showLogin);
  };

  const handleLoginSuccess = () => {
    setShowLogin(false); // Close the dropdown on successful login
  };

  /* Moved into Login.tsx: Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowLogin(false); //close dropdown if clicked outside (using these event handlers below to detect the click)
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);*/

  const handleRegistrationComplete = () => {
    console.log('Registration complete, redirecting to SAT practice');
    window.location.href = '/sat'; // Redirect to the desired path
    //navigate('/sat');  // Or wherever you want to redirect
  };
  
  //UserProvider wraps everything, making the user context available everywhere.
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <CssBaseline /> {/* Provides consistent baseline styles - haven't fully vetted this, so double check sometime after 12/10/2024*/}
        <UserProvider>
          <Router>
            <Box sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
              <AppBar component="header" position="absolute"
                sx={{zIndex: 2, backgroundColor: 'transparent', color: theme.palette.primary.main, 
                  '& .MuiToolbar-root': { fontSize: '1.5rem', position: 'relative', zIndex: 2 },
                  boxShadow: 'none'}}
              > 

                <Box sx={{position: 'absolute',top: 0, left: 0, right: 0, bottom: 0,
                  background: `linear-gradient(to bottom, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 0.8) 100%)`,
                  zIndex: 1, backdropFilter: 'blur(10px)',
                  maskImage: 'linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)',}}>
                </Box>
                <Toolbar sx={{gap: 2,height: '15vh', minHeight: { xs: '56px', sm: '64px', md: '72px', lg: '80px' } }}>
                  {isMobile && (

                    
                    <IconButton color="inherit" aria-label="open drawer" edge="start"
                      onClick={handleDrawerToggle} sx={{ mr: 2 }} >
                      <MenuIcon />
                    </IconButton>
                  )}
                  
                  <Typography variant="h3" fontWeight="bold" component="div" sx={{ flexGrow: 1 }}>Next Step Education</Typography>

                  {/* Desktop Navigation */}
                  {!isMobile && (
                    <Box sx={{ display: 'flex', gap: 2}}>
                      {navItems.map((item) => (
                        <Button key={item.text} component={RouterLink} to={item.path} 
                          color="inherit" startIcon={item.icon}
                          sx={{fontSize: { xs: '1rem', sm: '1.2rem', md: '1.4rem', lg: '1.5rem'},
                            '& .MuiButton-startIcon': {marginRight: 1},
                            position: 'relative',  // Required for absolute positioning of pseudo-element
                            '&::after': {
                              content: '""', position: 'absolute', width: '0', height: '2px', bottom: 0, left: '50%',
                              backgroundColor: 'currentColor', transition: 'all 0.2s ease',
                            },
                            '&:hover::after': {
                              width: '100%',
                              left: '0',
                            },
                            '&:hover': {
                              backgroundColor: 'rgba(255, 255, 255, 0.2)', // More visible background change
                            },
                          
                          }}
                          >
                          {item.text}
                        </Button>
                      ))}
                    </Box>
                  )}

                  {/* Login Button */}
                  <Box sx={{ ml: 2 }}>
                    <Login onLoginSuccess={handleLoginSuccess} toggleDropdown={toggleDropdown} 
                      dropdownRef={dropdownRef} showLogin={showLogin} />
                  </Box>
                </Toolbar>
              </AppBar>

              {/* Mobile Navigation Drawer */}
              <Drawer variant="temporary" anchor="left" open={mobileOpen} onClose={handleDrawerToggle}
                ModalProps={{keepMounted: true}} // Better open performance on mobile.
                sx={{display: { xs: 'block', sm: 'none' },
                  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                }}
              >
                {drawer}
              </Drawer>

              <Box component="main" sx={{flexGrow: 1, width: '100%', margin: 0, padding: 0}}>{/*flexGrow: 1 makes the box take up the remaining space, so footer is at the bottom*/}
                <Routes> 
                  <Route path ="/" element={<HomePage />} />
                  <Route path ="/sat" element={<SecondPage />} />
                  <Route path ="/create-profile" element={<CreateProfile onRegistrationComplete={handleRegistrationComplete}/>} />
                </Routes>
              </Box>
                  

              <Box component="footer" sx={{backgroundColor: theme.palette.custom.lightGrey, color: theme.palette.custom.darkGrey, 
                mt: 'auto', padding: { xs: '1rem', sm: '1.5rem' }, minHeight: '10vh'}}>
                  <Typography variant="body2" sx={{textAlign: 'left', marginLeft: '3vw'}}>© 2024 Next Step Education AI Tutoring. All rights reserved.</Typography>
                  <Typography variant="body2" sx={{textAlign: 'left', marginLeft: '3vw'}}>SAT® is a registered trademark of the College Entrance Examination Board™. The College Entrance Examination Board™ does not endorse, nor is it affiliated in any way with the owner or any content of this website.</Typography>
              </Box>

            </Box>
          </Router>
        </UserProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;

/*
<div className="App">
            <div className="App-content">
<header className="App-header">
                
                <h1>Next Step Education SAT Prep AI</h1>
                <div className="nav-bar">
                  <nav>
                    <a href="/" className="nav-link">Home</a>
                    <a href="/sat" className="nav-link">SAT</a>
                    <a href="/create-profile" className="nav-link">Create Profile</a>
                  </nav>
                </div>


                {/* Render the Login component here }
                <Login onLoginSuccess={handleLoginSuccess} toggleDropdown={toggleDropdown} dropdownRef={dropdownRef} showLogin={showLogin} />

              </header>

              </div>
          </div>
*/

//<img src='/images/logo-N.png'  alt="Next Step Education Logo" className="App-logo"/> //logo that was previously in teh top left corner

//put this after dropDownRef:
//const {user, loading, initialLoading} = useContext(UserContext); //loads logged in user's info here.

  /*only runs when user or loading change
  useEffect(() => {
    console.log('User data in App.tsx:', user); //NEW on 11/15 at 7:35 am: Log user data for debugging purposes
    console.log(`User's first name is ${user?.firstName}`);
    console.log('Loading state:', loading);
  }, [user, loading]);
  */
  
  /* Function to truncate the name if longer than 12 characters
  const getDisplayName = (name: string) => {
    return name.length > 12 ? `${name.slice(0, 9)}...` : name;
  }; */
  
  // Toggle dropdown visibility




//Put this after <Login>
/* Display user's first name if logged in, else show "Login" 3:19 am 11/13 - removed "!loading && " before "user" maybe add back in if it's messed up!}
          {initialLoading ? (
              <button className="login-button">Loading...</button>
            ) : user ? (
              <button className="login-button" onClick={toggleDropdown}>
                {getDisplayName(user.firstName)}
              </button>
            ) : (
              <button className="login-button" onClick={toggleDropdown}>Login</button>
            )}


          {/* Conditionally render the dropdown          
          <div ref={dropdownRef} className={`login-dropdown ${showLogin ? 'show' : ''}`}>
              <Login onLoginSuccess={handleLoginSuccess}/>
            </div> */






//Removed Registration page: <a href="/register" className="nav-link">Sign Up</a>

//previous Login, next to all the other nav hrefs: <a href="/login" className="nav-link">Login</a>
//Router to now nonexistent Login page: <Route path="/login" element={<Login />} />



//import reportWebVitals from './reportWebVitals.js';
//import BackendTestComponent from './components/BackendTestComponent.js'; //<BackendTestComponent />
// //put this  nearby ChatComponent if you want, although it's not working now on 6/24 at 4:53 pm

